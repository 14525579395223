import React, { Component } from "react"
import { Container, Form, Col, Row, Button } from "react-bootstrap"
import { connect } from "react-redux";
import { bindActionCreators } from "redux"; 
import { buyTickets } from "../actions/adminActions";

class BuyTickets extends Component {

    constructor() {
        super();
        this.state = {
            draws: '3',
            ticketCost: '2'
        }
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }

    handleBuyTicketsSubmit = (e) => {
        e.preventDefault();
        this.props.buyTickets(e.target.elements.draws.value, e.target.elements.ticketCost.value);
        e.target.reset();
    }

    render() {
        return (
            <Container fluid="md">
                <h2>Buy Tickets</h2>
                <Form onSubmit={this.handleBuyTicketsSubmit}>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label htmlFor="draws"># Draws</Form.Label>
                            <Form.Control type="number" value={this.state.draws} id="draws" name="draws"
                                onChange={(event) => this.handleUserInput(event)} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label htmlFor="ticketCost">$ Cost / Ticket</Form.Label>
                            <Form.Control type="number" value={this.state.ticketCost} id="ticketCost" name="ticketCost"
                                onChange={(event) => this.handleUserInput(event)} />
                        </Form.Group>
                    </Row>
                    <Button type="submit">Buy</Button>
                </Form>
                <Row className={(this.props.purchaseMessage) ? "" : "invisible"}>
                    {this.props.purchaseMessage} 
                </Row>
            </Container >
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    buyTickets
}, dispatch)

const mapStateToProps = (state) => {
    return {
        purchaseMessage : state.admin.message       
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BuyTickets)
