import { trackPromise } from "react-promise-tracker"

export const FETCH_MEMBER_INFO = "FETCH_MEMBER_INFO"
export const FETCH_MEMBERS_FAILED = "FETCH_MEMBERS_FAILED"

let targetURL = 'https://sl58u0na5m.execute-api.us-east-2.amazonaws.com/prod/pay-the-winners-lottery/lotto_members.json'
// let targetURL = 'https://cryptic-chamber-30980.herokuapp.com'


export const fetchMembers = () => {
    return dispatch => {
        trackPromise(
        fetch(`${targetURL}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                }
            })
            .then(response => {
                return response.json();
                // response.json()
                // console.log(response)
            })
            .then(members => {
                console.log(members);
                dispatch({
                type: FETCH_MEMBER_INFO,
                payload: members
            })
            
        })
            .catch(err => dispatch({
                type: FETCH_MEMBERS_FAILED,
                payload: err
            }))
         );   
    };
}