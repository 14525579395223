import React from "react";
import { Container, Form, Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Component } from "react";
import { bindActionCreators } from "redux"; 
import { addFunds } from "../actions/adminActions";

class AddFunds extends Component {

    constructor() {
        super();
        this.state = {
            memberId: '',
            amount : '20'
        }

    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }

    handleFundsSubmit = (e) => {
        e.preventDefault();
        this.props.addFunds(e.target.elements.member.value,e.target.elements.amount.value);
        e.target.reset();
    }

    render () {
        return (
            <Container fluid="md">
            <h2>Add Funds</h2>
            <Form onSubmit={this.handleFundsSubmit}>
                <Row>
                <Form.Group as={Col}>
                    <Form.Label>Member</Form.Label>
                    <Form.Select
                         
                        id = "member"
                        name = "member"
                            onChange={(event) => this.handleUserInput(event)} >
                        { this.props.members.map(member => <option key={member.id} value={member.memberId}>{member.firstName} {member.lastName.charAt(0)}</option>)}
                        
                    </Form.Select>
                </Form.Group>
    
                <Form.Group as={Col}>
                    <Form.Label htmlFor="amount" >$ Amount</Form.Label>
                    <Form.Control type="number" value={this.state.amount} id="amount" name="amount" 
                      onChange={(event) => this.handleUserInput(event)} />
                </Form.Group>
                </Row>
                <Button type="submit">Add Funds</Button>       
        </Form>
        <Row className={(this.props.updatedMember.memberId) ? "" : "invisible"}>
            {this.props.updatedMember.firstName} {this.props.updatedMember.lastName} has a new balance of ${this.props.updatedMember.balance}
        </Row>
        </Container >

        )
    }

}

const mapDispatchToProps = dispatch => bindActionCreators({
    addFunds
}, dispatch)

const mapStateToProps = (state) => {
    return {
        members: state.info,
        updatedMember : state.admin.member
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddFunds)