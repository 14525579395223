import { trackPromise } from "react-promise-tracker"

export const ADD_FUNDS = "ADD_FUNDS"
export const ADD_FAILED = "ADD_FAILED"
export const BUY_TICKETS = "BUY_TICKETS"
export const BUY_FAILED =  "BUY_FAILED"

// let targetURL = 'http://localhost:8080'

let targetURL = 'https://cryptic-chamber-30980.herokuapp.com'

export const addFunds = (memberId, amount) => {
    return dispatch => {
        trackPromise(
        fetch(`${targetURL}/api/addfunds/${memberId}?amt=${amount}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
            .then(response => response.json())
            .then(member => dispatch({
                type: ADD_FUNDS,
                payload: member
            }))
            .catch(err => dispatch({
                type: ADD_FAILED,
                payload: err
            }))
         );   
    };
}

export const buyTickets = (draws, ticketCost) => {
    let ticketPurchase = {
        purchaseType : "Pool",
        numDraws : draws,
        ticketCost :  ticketCost
    }
    return dispatch => {
        fetch(`${targetURL}/api/buytickets`, 
        {
            method: 'POST',
            body: JSON.stringify(ticketPurchase),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        })
            .then(response => response.text())
            .then(response =>
                dispatch({
                    type: BUY_TICKETS,
                    payload: response
                }))
            .catch(err => dispatch({
                type: BUY_FAILED,
                payload: err
            }))
    }
}