import React from "react"
import { Row, Col, Container } from "react-bootstrap"

// const displayFundDate = (member) => {
//     console.log("checking...");
//     if (member.lastFundUpdate !== undefined) return member.lastFundUpdate;
//     else return '';
// }

const MemberInList = ({ member }) => (
    <Container fluid="md">
    <Row className="bg-light border">

        <Col>{member.firstName} {member.lastName.charAt(0)}</Col>
        <Col>${member.balance}</Col>
        <Col>{ (member.lastFundUpdate) ? member.lastFundUpdate: ''}</Col>

    </Row>
    </Container>


)

export default MemberInList