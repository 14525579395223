import React, { Component } from "react"
import { Row, Col, Container } from "react-bootstrap"
import { connect } from "react-redux"

class Jackpot extends Component {
    render() {
        return (
            <Container className="bg-primary " >
                {/* <Row >
                <Col>Current Jackpot: </Col>
                <Col>Next Draw:</Col>
            </Row> */}
                <nav className=" navbar-dark ">
                    <Row>
                        <Col > 
                            <a className="navbar-brand" href="https://www.megamillions.com/">Mega Millions</a>
                        </Col>
                        {/* <Col>${this.props.drawJackpot.jackpot / 1000000} million</Col>
                <Col>{this.props.drawJackpot.next_draw}</Col> */}
                    </Row>
                </nav>
            </Container>
        )

    }
}


const mapStateToProps = (state) => {
    return {
        drawJackpot: state.jackpot
    }
}

export default connect(mapStateToProps, null)(Jackpot)