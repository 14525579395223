import React from "react"
import { Row, Col, Container } from "react-bootstrap"

const PaymentInfo = () => (
    <Container className="bg-primary">

        <nav className=" navbar-dark">

            <Row xs={1} sm={2} md={3} lg={4}>
            <Col ><a className="navbar-brand" href="https://www.paypal.me/paythewinners">PayPal</a></Col>
                <Col><a className="navbar-brand" href="https://venmo.com/code?user_id=2855347341492224451&created=1633213081.821725&printed=1">Venmo</a></Col>
                <Col className="navbar-brand">Zelle: pay.the.winners@gmail.com or admins mobile phone#</Col>
            </Row>
        </nav>
    </Container>
)

export default PaymentInfo