import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import infoReducer from './reducers/infoReducer'
import logger from 'redux-logger'
import adminReducer from './reducers/adminReducers'
// import jackpotReducer from './reducers/jackpotReducer'

const rootReducer = combineReducers({
  info: infoReducer,
  admin : adminReducer
  // jackpot : jackpotReducer
})

export default () => createStore(rootReducer, applyMiddleware(logger, thunkMiddleware))