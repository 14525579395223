import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Main from './components/Main';
import AdminItems from './components/AdminItems';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchMembers } from './actions/infoActions';
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {

  componentDidMount() {
    this.props.fetchMembers();
    // this.props.fetchJackpot();
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/">
              <Main />
            </Route>
            {/* <Route path="/luckycharms">
              <AdminItems/>
            </Route> */}
          </Switch>
        </Router>

      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchMembers, 
  // fetchJackpot
}, dispatch)


export default connect(null, mapDispatchToProps)(App)

