import {
    FETCH_MEMBER_INFO,
    FETCH_MEMBERS_FAILED
  } from '../actions/infoActions'
  
  
  
  let initialState = []
  
  
  const infoReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_MEMBER_INFO:
        console.log(action.payload);
        // return [{"id":14,"balance":68,"first_name":"Gina","joined_date":"2017.09.17","last_name":"Prine","member_id":"MEMBER-12","status":"IN"}];
        return action.payload.filter(member => member.status === "IN").sort((val1, val2) => val1.balance - val2.balance);
      case FETCH_MEMBERS_FAILED:
        return action.payload
      default:
        return state
    }
  
  }
  
  export default infoReducer;
  