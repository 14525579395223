import {
  ADD_FUNDS,
  ADD_FAILED,
  BUY_TICKETS,
  BUY_FAILED
} from '../actions/adminActions'


let initialState = {
  member: "",
  message: ""
};


const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FUNDS:
      return { ...state, member: action.payload };
    case BUY_TICKETS:
      return { ...state, message: action.payload }
    case BUY_FAILED:
    case ADD_FAILED:
      return action.payload
    default:
      return state
  }

}

export default adminReducer;