import React, { Component } from "react"
import MemberInList from "./MemberInList"
import { connect } from "react-redux"
import { Container,
        Row,
        Stack
 } from "react-bootstrap"   
import PaymentInfo from "./PaymentInfo"
import Jackpot from "./Jackpot"

class Main extends Component {

    render() {
        return (
            <Container>
                <h2>Pay The Winners!</h2>
                <Jackpot />
                <h3>Balance Info</h3>
                <Stack gap={4}>
                <Row > 
                    {this.props.members.map(member => <MemberInList key={member.id} member={member} />)}

                </Row>

                <PaymentInfo />
                </Stack>
            </Container>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        members: state.info
    }
}

export default connect(mapStateToProps, null)(Main)


